import { DEFAULT_LANGUAGE as LANG } from './feature-flags';
/**
 * ************************************************
 * Time & Language Property
 * ************************************************
 */
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import 'dayjs/locale/en';

export const PASSWORD_LINK_GENERATOR = 'marryingbeb';
/**
 * ************************************************
 * Flag to to set Bride to BOY first
 * ************************************************
 */
export const IS_BOY_FIRST = true;

/**
 * ************************************************
 * Bride & Groom Info
 * ************************************************
 */
export const GIRL_NAME = 'Eufrasia Beby Andrea';
export const GIRL_NAME_SHORT = 'Beby';
export const GIRL_FATHER_NAME = `Tjen Tjin Jung`;
export const GIRL_MOTHER_NAME = `Pola Walakandou`;
export const GIRL_PARENT_NAME =
  LANG === 'en'
    ? `The Daughter of Mr. ${GIRL_FATHER_NAME} & Mrs. ${GIRL_MOTHER_NAME}`
    : `Putri dari Bapak ${GIRL_FATHER_NAME} & Ibu ${GIRL_MOTHER_NAME}`;
// -> boy section
export const BOY_NAME = 'Martinus Tiodorus Panirman';
export const BOY_NAME_SHORT = 'Martin';
export const BOY_FATHER_NAME = `John Panirman`;
export const BOY_MOTHER_NAME = `Fransisca Fanny`;
export const BOY_PARENT_NAME =
  LANG === 'en'
    ? `The Son of Mr. ${BOY_FATHER_NAME} & Mrs. ${BOY_MOTHER_NAME}`
    : `Putra dari Bapak ${BOY_FATHER_NAME} & Ibu ${BOY_MOTHER_NAME}`;
// -> bride section
export const THE_BRIDE = IS_BOY_FIRST
  ? `${BOY_NAME_SHORT} & ${GIRL_NAME_SHORT}`
  : `${GIRL_NAME_SHORT} & ${BOY_NAME_SHORT}`;

/**
 * ************************************************
 * Instagram Profile Account
 * @important please put instagram id without `@`
 * ************************************************
 */
export const IG_BOY = 'vruska';
export const IG_GIRL = 'eufrasiiaa';
export const IG_FILTER = `https://www.instagram.com/ar/164626683400577/`;

/**
 * ************************************************
 * SEO Requirement
 * @important - Don't forget to update SEO IMAGE
 * ************************************************
 */
export const SEO_IMAGE = `https://ik.imagekit.io/masansgaming/Martinus%20Beby/SEO_ETSsH3ilt.jpg?updatedAt=1701145606763`;
export const SEO_URL = 'https://invitato.net/marryingbeb';
export const SEO_TITLE = `The Wedding of ${THE_BRIDE} by Invitato`;
export const SEO_DESCRIPTION =
  LANG === 'en'
    ? `Together with joyful hearts, we re pleased to announce the beginning of this new chapter of our lives together. Please click the Website Invitation link on this message for more information about our wedding details.`
    : `Menjadi sebuah kebahagiaan bagi kami untuk mengumumkan awal dari babak baru kehidupan kami bersama. Silakan klik tautan situs Undangan Website di bawah untuk informasi lebih lanjut:`;

/**
 * ************************************************
 * Time requirement for Counting Down, and
 * Remind me generator
 * @important - please convert time to Epoch time by
 * using this link https://www.epochconverter.com/
 * ************************************************
 */
export const EPOCH_START_EVENT = 1705643400;
export const EPOCH_RESEPSI_START = 1705643400;
export const EPOCH_END_EVENT = 1705762800;

/**
 * ************************************************
 * DATE REQUIREMENT
 * @important - Format date YYYY-MM-DD
 * ************************************************
 */
export const DATE_AKAD = new Date('2024-01-19');
export const DATE_RESEPSI = new Date('2024-01-20');

export const WEDDING_AKAD_TIME = '12.50 PM';
export const WEDDING_AKAD_LOC_NAME = 'Jakarta Cathedral (Gereja Katedral Jakarta)';
export const WEDDING_AKAD_LOC_ROAD = `Jalan Katedral No. 7B, Pasar Baru, Kecamatan Sawah Besar, Kota Jakarta Pusat, DKI Jakarta 10710`;
export const WEDDING_AKAD_DRESSCODE = ``;
export const WEDDING_AKAD_FULLDATE = dayjs(DATE_AKAD).locale(LANG).format('dddd, DD MMMM YYYY');

export const WEDDING_RESEPSI_TIME = '07.00 PM - Finish';
export const WEDDING_RESEPSI_LOC_NAME = `Harris Suites Puri Mansion (Restaurant Area)`;
export const WEDDING_RESEPSI_LOC_ROAD = `Puri Mansion Estate, Jl. Puri Lkr. Luar, Kec. Kembangan, Daerah Khusus, Ibukota Jakarta 11610`;
export const WEDDING_RESEPSI_DRESSCODE = `Just Be You`;
export const WEDDING_RESEPSI_FULLDATE = dayjs(DATE_RESEPSI)
  .locale(LANG)
  .format('dddd, DD MMMM YYYY');
export const WEDDING_RESEPSI_DATE = WEDDING_AKAD_FULLDATE;
// export const WEDDING_RESEPSI_DATE = dayjs(DATE_RESEPSI)
//   .locale(DEFAULT_LANGUAGE)
//   .format('DD • MM • YYYY');

/**
 * ********************************************************
 * Link Generator V2
 * @important - this info will be applied at link generator
 * ********************************************************
 */
export const HOSTNAME = 'https://marryingbeb.com/';
export const BROADCAST_WEDDING_LOCATION = `Harris Suites Puri Mansion (Restaurant Area)`;
export const BROADCAST_WEDDING_DAY = {
  id: dayjs(DATE_RESEPSI).locale('id').format('dddd, DD MMMM YYYY'),
  en: dayjs(DATE_RESEPSI).locale('en').format('dddd, DD MMMM YYYY'),
};

/**
 * ************************************************
 * Maps Location
 * ************************************************
 */
export const GOOGLE_MAPS_LINK = `https://maps.app.goo.gl/FE8snNQGhy7SAX2WA`;
export const GOOGLE_MAPS_ADDRESS = `Showed in wedding details`;

/**
 * ************************************************
 * Backsound Copyright
 * ************************************************
 */
export const SOUND_BY = `Redbone – Come and Get Your Love`;
export const SOUND_URL = 'https://www.youtube.com/watch?v=bc0KhhjJP98';

/**
 * ************************************************
 *  Youtube Live requirement
 * ************************************************
 */
export const YOUTUBE_LINK = 'https://youtu.be/ApX0LaS6gSM';
export const YOUTUBE_EMBED = 'https://www.youtube.com/embed/ApX0LaS6gSM';
export const YOUTUBE_PREWEDDING_EMBED = `https://www.youtube.com/embed/WEC5RezD5jU`;
export const YOUTUBE_PREWEDDING_LINK = `https://youtu.be/WEC5RezD5jU`;

/**
 * ************************************************
 *  RSVP & Wishlish Section
 * ************************************************
 */
export const API_HOSTNAME = `https://script.google.com/macros/s/AKfycbwOQ0DYUUO9M9he9IH-WUMQy9Q3LsSqhWbOOh-E4B5gln8k_o_AMmGcYFh1N8twYzB2/exec`;
export const API_TABLE_NAME = 'wishlist';
export const API_RSVP_TABLE = 'rsvp';
export const SHEET_GIFT_CONFIRMATION = 'gift_confirmation';

/**
 * ************************************************
 *  Invitato Link Requirement
 * ************************************************
 */
export const URL_INVITATO = 'https://invitato.net';
export const URL_IG_INVITATO = 'https://www.instagram.com/dindadipoyono/';
export const URL_WA_INVITATO =
  'https://wa.me/+628112778189?text=Hi%20Kak,%20aku%20mau%20pesan%20undangan%20online%20nih!';
